import { IconX, IconInfoCircle, IconBuildingStore, IconCheck, IconPhone, IconArrowsTransferUp } from '@tabler/icons-react';

export type OutcomeType = 'no_action' | 'information_provided' | 'business_follow_up' | 'completed' | 'caller_follow_up' | 'transferred';

export const outcomeConfig = {
    'no_action': {
        color: '#d32f2f',
        icon: IconX,
        size: 16
    },
    'information_provided': {
        color: '#0288d1',
        icon: IconInfoCircle,
        size: 16
    },
    'business_follow_up': {
        color: '#ed6c02',
        icon: IconBuildingStore,
        size: 16
    },
    'completed': {
        color: '#2e7d32',
        icon: IconCheck,
        size: 16
    },
    'caller_follow_up': {
        color: '#1976d2',
        icon: IconPhone,
        size: 16
    },
    'transferred': { 
        color: '#757575',
        icon: IconArrowsTransferUp,
        size: 16
    }
} as const; 
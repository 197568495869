import { useGetIdentity, Title, useTranslate, usePermissions } from "react-admin";
import { Card, CardContent, CardHeader, Typography, Grid, CircularProgress, Box } from "@mui/material";
import { useEffect, useState, useCallback } from 'react';
import ConversationStatsChart from './components/dashboard/ConversationStatsChart';
import { dataProvider } from './dataProvider';
import { TotalCallsCard } from './components/dashboard/TotalCallsCard';
import { DurationCard } from "./components/dashboard/DurationCard";
import { DashboardFilters } from './components/dashboard/DashboardFilters';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CallIcon from '@mui/icons-material/Call';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { useSearchParams } from 'react-router-dom';

interface StatsData {
  call_count: number;
  call_duration: number;
  transferred_count: number;
  transfer_duration: number;

}

interface StatsFilters {
  assistant_ids?: number[];
  customer_ids?: number[];
  start_date?: Date;
  end_date?: Date;
}

export const Dashboard = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<StatsFilters>(() => {
    const assistant_ids = searchParams.get('assistant_ids')?.split(',').map(Number).filter(Boolean);
    const customer_ids = searchParams.get('customer_ids')?.split(',').map(Number).filter(Boolean);
    const start_date = searchParams.get('start_date') ? new Date(searchParams.get('start_date')!) : undefined;
    const end_date = searchParams.get('end_date') ? new Date(searchParams.get('end_date')!) : undefined;
    
    return {
      ...(assistant_ids?.length ? { assistant_ids } : {}),
      ...(customer_ids?.length ? { customer_ids } : {}),
      ...(start_date ? { start_date } : {}),
      ...(end_date ? { end_date } : {})
    };
  });
  const [dailyStats, setDailyStats] = useState<StatsData>();
  const [totalStats, setTotalStats] = useState<StatsData>();
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilterSection, setActiveFilterSection] = useState<string | null>(null);

  const fetchStats = useCallback(() => {
      setLoading(true);
      
      dataProvider.custom('api/charts/conversation-stats', {
          method: 'post',
          postdata: {
              assistant_ids: filters.assistant_ids,
              customer_ids: filters.customer_ids,
              start_date: filters.start_date,
              end_date: filters.end_date
          }
      })
      .then((response: { data: { daily_stats: any, total_stats: any } }) => {
          setDailyStats(response.data.daily_stats);
          setTotalStats(response.data.total_stats);
          setLoading(false);
      })
      .catch((error: Error) => {
          console.error('Error fetching stats:', error);
          setLoading(false);
      });
  }, [filters, permissions]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const handleFilterChange = (newFilters: StatsFilters) => {
    const updatedFilters = { ...filters, ...newFilters };
    setFilters(updatedFilters);
    
    const params = new URLSearchParams();
    if (updatedFilters.assistant_ids?.length) {
      params.set('assistant_ids', updatedFilters.assistant_ids.join(','));
    }
    if (updatedFilters.customer_ids?.length) {
      params.set('customer_ids', updatedFilters.customer_ids.join(','));
    }
    if (updatedFilters.start_date) {
      params.set('start_date', updatedFilters.start_date.toISOString());
    }
    if (updatedFilters.end_date) {
      params.set('end_date', updatedFilters.end_date.toISOString());
    }
    setSearchParams(params);
  };

  const clearAllFilters = () => {
    setFilters({});
    setSearchParams(new URLSearchParams());
  };

  return (
    <>
      <Title title="Dashboard" />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Card>
          {identityLoading ? (
                <CircularProgress />
              ) : identity ? (
                <>
                  <CardHeader 
                    title={`${translate("resources.dashboard.welcome_back")}, ${identity.first_name} 👋`}
                    subheader={`${translate("resources.dashboard.subheader")}`}
                  />
                </>
              ) : (
                <Typography sx={{ mb: 1 }}>{translate("resources.dashboard.welcome_back")}</Typography>
              )}            
            <CardContent>            
                <>
                  <DashboardFilters 
                    open={showFilters}
                    onClose={() => {
                      setShowFilters(false);
                      setActiveFilterSection(null);
                    }}
                    onOpen={() => {
                      setShowFilters(true);
                    }}
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    permissions={permissions}
                    activeSection={activeFilterSection}
                    onClearFilters={clearAllFilters}
                  />
                  
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                    <Box sx={{ pt: 2, pb: 2 }}>
                      <Grid 
                        container 
                        spacing={2}
                        sx={{ justifyContent: 'flex-start' }}
                      >
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <DurationCard 
                            seconds={totalStats?.call_duration || 0} 
                            title={translate("resources.conversations.stats.total_call_time")}
                            icon={<AccessTimeIcon />}
                            tooltip={translate("resources.conversations.stats.total_call_time_help")}
                          />                          
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TotalCallsCard 
                            total={Math.round(totalStats?.call_count || 0)} 
                            title={translate("resources.conversations.stats.total_calls")} 
                            icon={<CallIcon />}
                            tooltip={translate("resources.conversations.stats.total_calls_help")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TotalCallsCard 
                            total={totalStats?.call_count && totalStats?.transferred_count 
                              ? Math.round((totalStats.transferred_count / totalStats.call_count) * 100)
                              : 0} 
                            title={translate("resources.conversations.stats.transfer_count_percentage")} 
                            icon={<SwapCallsIcon />}
                            suffix="%"
                            tooltip={translate("resources.conversations.stats.transfer_count_percentage_help")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TotalCallsCard 
                            total={totalStats?.call_duration && totalStats?.transfer_duration 
                              ? Math.round((totalStats.transfer_duration / totalStats.call_duration) * 100)
                              : 0} 
                            title={translate("resources.conversations.stats.transfer_duration_percentage")} 
                            icon={<AvTimerIcon />}
                            suffix="%"
                            tooltip={translate("resources.conversations.stats.transfer_duration_percentage_help")}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <ConversationStatsChart data={dailyStats} onFilterChange={handleFilterChange} />
                    </>
                  )}
                </>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
import { useEffect } from 'react';
import { LayoutProps, useLocaleState, useAuthProvider, CheckForApplicationUpdate, List } from 'react-admin';
import CustomMenu from './CustomMenu';
import { Box, Drawer, IconButton, useMediaQuery, useTheme, AppBar as MuiAppBar, Toolbar, Divider, ListItem } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from './Logo';
import { useSidebarState } from 'react-admin';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UserMenu from './UserMenu';
import { IconBurger, IconMenu2 } from '@tabler/icons-react';

// Simple MUI AppBar
const SimpleAppBar = ({ onMenuClick }: { onMenuClick: () => void }) => (
    <MuiAppBar position="fixed" sx={{ backgroundColor: 'white', color: 'black', height: '64px' }}>
        <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={onMenuClick}
            >
                <MenuIcon />
            </IconButton>
            <Box flex={1} />
        </Toolbar>
    </MuiAppBar>
);

export default ({ children, ...props }: LayoutProps) => {
    const authProvider = useAuthProvider();
    const [currentLocale, setLocale] = useLocaleState();
    const [sidebarOpen, setSidebarOpen] = useSidebarState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmallScreen = useMediaQuery('(max-width: 1440px)'); // 13-14" laptops typically
    
    const drawerWidth = !isSmallScreen ? '13vw' : '16vw';
    const collapsedWidth = '65px';

    // Auth effect
    useEffect(() => {
        if (authProvider && authProvider.getIdentity) {
            authProvider.getIdentity().then(({ locale }) => {
                let dbLocale = locale.replace('-','_');
                if (dbLocale !== currentLocale) {
                    setLocale(dbLocale || 'en_US');
                }
            });
        }
    }, [authProvider, setLocale]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            minHeight: '100vh',
            backgroundColor: !isMobile ? theme.palette.background.paper : theme.palette.background.default
        }}>
            {isMobile && <SimpleAppBar onMenuClick={toggleSidebar} />}

            <Drawer
                variant={!isMobile ? "permanent" : "temporary"}
                open={!isMobile ? true : sidebarOpen}
                onClose={toggleSidebar}
                sx={{
                    height: '100%',
                    width: !isMobile ? (sidebarOpen ? drawerWidth : collapsedWidth) : drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: !isMobile ? (sidebarOpen ? drawerWidth : collapsedWidth) : drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: theme.palette.background.paper,
                        borderRight: 'none',
                        overflowX: 'hidden',
                        minWidth: !isMobile ? (sidebarOpen ? '200px' : '65px') : '200px',
                        maxWidth: !isMobile ? (sidebarOpen ? '400px' : '65px') : '400px',
                        height: '100%',
                        transition: theme => theme.transitions.create(['width', 'min-width', 'max-width'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex', 
                        flexDirection: 'row', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        pt: 2, 
                        pb: 2,
                        gap: 5
                    }}
                >
                    {sidebarOpen && (
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Logo width={100}/>
                        </Box>
                    )}
                    <IconButton onClick={toggleSidebar}>
                        {sidebarOpen ? <ChevronLeftIcon /> : <IconMenu2 />}
                    </IconButton>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%'}}>
                    <CustomMenu />
                    <Box sx={{ width: '100%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Divider sx={{ width: '100%', mb: 2 }} />
                        <UserMenu />
                    </Box>
                </Box>
            </Drawer>

            <Box
                component="main"
                sx={{
                    width: `calc(100% - ${!isMobile ? (sidebarOpen ? '200px' : '65px') : '200px'})`,
                    height: '100vh',
                    pt: isMobile ? '64px' : 2,
                    // Removed the invalid property 'B'
                    pb: !isMobile ? 2 : 0,
                    pr: !isMobile ? 2 : 0,
                }}
            >
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.default,
                        borderRadius: 2,
                        height: '100%',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                        overflow: 'scroll',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {children}
                    <CheckForApplicationUpdate />
                </Box>
            </Box>
        </Box>
    );
};
import { useTheme } from '@mui/material/styles';

interface RatingBadgeProps {
    value: number;
}

export const RatingBadge = ({ value }: RatingBadgeProps) => {
    const theme = useTheme();

    // Define colors for different ratings
    const getRatingColor = (rating: number) => {
        switch (rating) {
            case 1:
                return theme.palette.error.main;
            case 2:
                return theme.palette.warning.main;
            case 3:
                return theme.palette.warning.light;
            case 4:
                return theme.palette.success.main;
            case 5:
                return theme.palette.success.dark;
            default:
                return theme.palette.grey[400];
        }
    };

    return (
        <div
            style={{
                backgroundColor: getRatingColor(value),
                color: '#fff',
                borderRadius: '50%',
                width: '28px',
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '14px'
            }}
        >
            {value}
        </div>
    );
}; 
import { Form, useTranslate } from "react-admin";
import { Box, Drawer, Button, Typography, Paper } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTheme } from "@mui/material/styles";
import { IconX } from "@tabler/icons-react";
import React from "react";

interface FiltersDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  filters: any;
  onFilterChange: (filters: any) => void;
  onClearFilters: () => void;
  filterComponents: React.ReactNode[];
  showActiveFilters?: boolean;
  activeFiltersComponent?: React.ReactNode;
}

export const FiltersDrawer = ({
  open,
  onClose,
  onOpen,
  filters,
  onFilterChange,
  onClearFilters,
  filterComponents,
  showActiveFilters = true,
  activeFiltersComponent,
}: FiltersDrawerProps) => {
  const translate = useTranslate();
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "flex-end",
          alignItems: { xs: "stretch", sm: "center" },
          gap: 1,
          mb: 0,
        }}
      >
        <Box
          sx={{
            order: { xs: 2, sm: 1 },
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          {showActiveFilters && !open && activeFiltersComponent}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            order: { xs: 1, sm: 2 },
            justifyContent: "flex-end",
          }}
        >
          {Object.keys(filters).length > 0 && (
            <Button onClick={onClearFilters} variant="outlined" color="error">
              {translate("resources.generic.clear_filters")}
            </Button>
          )}
          <Button
            startIcon={<FilterListIcon />}
            onClick={onOpen}
            variant="outlined"
          >
            {translate("resources.generic.open_filters")}
          </Button>
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "400px",
            padding: theme.spacing(3),
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            bgcolor: theme.palette.background.paper,
          },
        }}
      >
        <Box sx={{ mb: 2, position: "relative" }}>
          <IconX
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              cursor: "pointer",
            }}
            onClick={onClose}
            size={24}
          />
          <Typography variant="h6" fontWeight="bold" fontSize="1.25rem">
            {translate("resources.generic.filters")}
          </Typography>
        </Box>
        <Paper
          sx={{
            p: theme.spacing(2),
            boxShadow: theme.shadows[0],
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            <Form>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                {filterComponents.map((component: any, index: number) => (
                  <React.Fragment key={component?.key || `filter-${index}`}>
                    {component}
                  </React.Fragment>
                ))}
              </Box>
            </Form>
          </Box>
        </Paper>
      </Drawer>
    </>
  );
};

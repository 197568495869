import { Typography } from '@mui/material';
import { useResourceContext, useTranslate } from 'react-admin';

export const ResourceTitle = () => {
    const translate = useTranslate();
    const resource = useResourceContext();
    return (
        <Typography
            variant="h3"
            fontWeight="bold"
            sx={{mb: 1}}
        >
            {resource ? translate(`resources.${resource}.overview`) : ''}
        </Typography>
    );
};
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { 
  IconSettings, 
  IconUser,
  IconChevronDown,
  IconUsers,
} from '@tabler/icons-react';
import { useSidebarState, useTranslate, Logout, useGetIdentity } from 'react-admin';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const {data} = useGetIdentity();
  const [sidebarOpen] = useSidebarState();
  const translate = useTranslate();
  const navigate = useNavigate();
  const email = 'aaa';

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    const parsedAuth = JSON.parse(auth || '{}');
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    navigate('/profile');
    handleClose();
  };

  const handleUsers = () => {
    navigate('/users');
    handleClose();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        onClick={handleClick}
        startIcon={<IconUser size={20} />}
        endIcon={sidebarOpen ? <IconChevronDown size={20} /> : null}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          padding: theme.spacing(1, sidebarOpen ? 2 : 1),
          color: 'inherit',
          minWidth: 'auto',
          width: '100%',
          justifyContent: sidebarOpen ? 'space-between' : 'center',
          '& .MuiButton-startIcon': {
            margin: sidebarOpen ? undefined : 0,
          },
          '& .MuiButton-endIcon': {
            marginLeft: 'auto',
          },
        }}
      >
        {sidebarOpen ? (data?.fullName) : null}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          '& .MuiPaper-root': {
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: '#F7F7F2',
            minWidth: '200px',
          },
        }}
      >
       
        <MenuItem onClick={handleSettings}>
          <ListItemIcon>
            <IconSettings size={20} stroke={1.5} />
          </ListItemIcon>
          <ListItemText>{translate('resources.generic.settings')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleUsers}>
          <ListItemIcon>
            <IconUsers size={20} stroke={1.5} />
          </ListItemIcon>
          <ListItemText>{translate('resources.generic.users')}</ListItemText>
        </MenuItem>
        <Logout />
      </Menu>
    </Box>
  );
};

export default UserMenu;
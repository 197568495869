import { Card, CardContent, Typography, Box, useTheme, Tooltip, IconButton } from "@mui/material";
import { Icon } from "@mui/material";
import { useLocaleState } from 'react-admin';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface DurationCardProps {
  seconds: number;
  title: string;
  icon: React.ReactNode;
  tooltip?: string;
}

export const DurationCard = ({ 
  seconds, 
  title, 
  icon,
  tooltip
}: DurationCardProps) => {
  const theme = useTheme();
  const [locale] = useLocaleState();

  const formatDuration = (totalSeconds: number) => {
    const safeLocale = locale?.replace('_', '-') || 'nl-NL';
    
    try {
      // Formatters voor de volledige unit namen
      const dayFormatter = new Intl.NumberFormat(safeLocale, { 
        style: 'unit',
        unit: 'day',
        unitDisplay: 'long',
      });
      
      const hourFormatter = new Intl.NumberFormat(safeLocale, { 
        style: 'unit',
        unit: 'hour',
        unitDisplay: 'long',
      });
      
      const minuteFormatter = new Intl.NumberFormat(safeLocale, { 
        style: 'unit',
        unit: 'minute',
        unitDisplay: 'long',
      });
      
      const secondFormatter = new Intl.NumberFormat(safeLocale, { 
        style: 'unit',
        unit: 'second',
        unitDisplay: 'long',
      });

      // Helper functie om eerste karakter van de unit te krijgen
      const getUnitAbbrev = (formattedString: string) => {
        // Zoek het eerste karakter na het getal
        const match = formattedString.match(/\d+\s*(.)/);
        return match ? match[1] : '';
      };

      const days = Math.floor(totalSeconds / 86400);
      const hours = Math.floor((totalSeconds % 86400) / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const secs = Math.floor(totalSeconds % 60);

      const pad = (num: number) => num.toString().padStart(2, '0');

      if (days > 0) {
        const dayUnit = getUnitAbbrev(dayFormatter.format(1));
        const hourUnit = getUnitAbbrev(hourFormatter.format(1));
        return `${days}${dayUnit} ${hours}${hourUnit}`;
      } else if (hours > 0) {
        const hourUnit = getUnitAbbrev(hourFormatter.format(1));
        const minuteUnit = getUnitAbbrev(minuteFormatter.format(1));
        return `${hours}${hourUnit} ${pad(minutes)}${minuteUnit}`;
      } else if (minutes > 0) {
        const minuteUnit = getUnitAbbrev(minuteFormatter.format(1));
        const secondUnit = getUnitAbbrev(secondFormatter.format(1));
        return `${minutes}${minuteUnit} ${pad(secs)}${secondUnit}`;
      } else {
        const secondUnit = getUnitAbbrev(secondFormatter.format(1));
        return `${secs}${secondUnit}`;
      }
    } catch (error) {
      // Fallback formatting met basis afkortingen
      const pad = (num: number) => num.toString().padStart(2, '0');
      
      const days = Math.floor(totalSeconds / 86400);
      const hours = Math.floor((totalSeconds % 86400) / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const secs = Math.floor(totalSeconds % 60);

      // Basis afkortingen voor fallback
      const units = { d: 'd', h: 'h', m: 'm', s: 's' };

      if (days > 0) {
        return `${days}${units.d} ${hours}${units.h}`;
      } else if (hours > 0) {
        return `${hours}${units.h} ${pad(minutes)}${units.m}`;
      } else if (minutes > 0) {
        return `${minutes}${units.m} ${pad(secs)}${units.s}`;
      } else {
        return `${secs}${units.s}`;
      }
    }
  };

  return (
    <Card 
      sx={{
        height: '100%',
        minHeight: 120,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: `${theme.palette.primary.main}12`,
        border: `1px solid ${theme.palette.primary.main}40`,
        boxShadow: `0 2px 4px ${theme.palette.primary.main}12`
      }}
    >
      <CardContent sx={{ py: 2 }}>
        <Box 
          display="flex" 
          alignItems="center" 
          mb={1}
          sx={{ 
            justifyContent: 'space-between'
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Icon>{icon}</Icon>
            <Typography variant="body1" component="div">
              {title}
            </Typography>
          </Box>
          {tooltip && (
            <Tooltip title={tooltip} arrow placement="top">
              <IconButton size="small">
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Typography variant="h3" component="div">
          {formatDuration(seconds)}
        </Typography>
      </CardContent>
    </Card>
  );
}; 
import {
    List,
    Toolbar,
    SaveButton,
    DeleteButton,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    SimpleForm,
    TextInput,
    BooleanInput,
    RadioButtonGroupInput,
    useRecordContext,
    ReferenceManyField,
    ReferenceField,
    useGetIdentity,
    TopToolbar,
    useTranslate,
    ReferenceInput,
    AutocompleteInput,
    useGetOne
} from 'react-admin';

import { CreateInDialogButton, EditInDialogButton } from '@react-admin/ra-form-layout';
import { useUsersWithPollie } from './hooks/useUsersWithPollie';
import CustomPagination from './components/react-admin/CustomPagination';
import { ResourceTitle } from './layout/ResourceTitle';
import { useState } from 'react';
import { FiltersDrawer } from './components/FiltersDrawer';
import { TaskFilters } from './components/tasks/TaskFilters';
import { Box } from '@mui/material';


const statuses = [{id:'none',name:'resources.conversation_notes.statuses.none'},{id:'open',name:'resources.conversation_notes.statuses.open'},{id:'closed',name:'resources.conversation_notes.statuses.closed'}]

export const CustomEditToolbar = () => (
    <Toolbar sx={{ justifyContent: 'space-between' }}>
        <SaveButton />
        <DeleteButton redirect={false} />
    </Toolbar>
);

const AddNoteForm = () => {
    const { data: identity } = useGetIdentity();
    const record = useRecordContext();
    const { data: assistant } = useGetOne(
        'assistants',
        { id: record?.assistant_id },
        { enabled: !!record?.assistant_id }
    );

    const { users, isLoading: usersLoading } = useUsersWithPollie({
        customerId: assistant?.customer_id
    });
    
    return (
        <CreateInDialogButton fullWidth label="resources.conversation_notes.add_note" ButtonProps={{ variant: 'text' }} title="resources.conversation_notes.add_note">
            <SimpleForm
                defaultValues={{
                    created_by: identity?.id, 
                    conversation_id: record?.id, 
                }}        
            >
                <TextInput source="content" label="resources.conversation_notes.fields.content" required fullWidth multiline inputProps={{ style: { minHeight: '150px' } }} />
                <BooleanInput source="is_private" label="resources.conversation_notes.fields.is_private" />
                <RadioButtonGroupInput source="status" choices={statuses} defaultValue={'open'} label="resources.conversation_notes.fields.status" />
                <ReferenceInput source="assigned_to" reference="users">
                    {usersLoading ? (
                        <span>Loading users...</span>
                    ) : (
                        <AutocompleteInput 
                            source="assigned_to"
                            choices={users}
                            optionText={(choice) => `${choice.first_name} ${choice.last_name} (${choice.email})`} 
                            label="resources.conversation_notes.fields.assigned_to"
                            filterToQuery={(searchText) => ({
                                first_name: searchText ? `%${searchText}%` : undefined,
                                customer_id: assistant?.customer_id
                            })}
                            fullWidth
                        />
                    )}
                </ReferenceInput>
            </SimpleForm>
        </CreateInDialogButton>
    );
};

const EditNoteButton = () => {  
    const note = useRecordContext();
    
    // Haal conversation data op
    const { data: conversation } = useGetOne(
        'conversations',
        { id: note?.conversation_id },
        { enabled: !!note?.conversation_id }
    );

    // Haal assistant data op
    const { data: assistant } = useGetOne(
        'assistants',
        { id: conversation?.assistant_id },
        { enabled: !!conversation?.assistant_id }
    );

    // Gebruik de nieuwe hook voor users
    const { users, isLoading: usersLoading } = useUsersWithPollie({
        customerId: assistant?.customer_id
    });

    return (
        <EditInDialogButton fullWidth inline resource='conversation-notes' title="resources.conversation_notes.edit_note">
            <SimpleForm toolbar={<CustomEditToolbar/>}>
                <TextInput 
                    source="content" 
                    required 
                    fullWidth 
                    multiline 
                    inputProps={{ style: { minHeight: '150px' } }} 
                    label="resources.conversation_notes.fields.content" 
                />
                <BooleanInput 
                    source="is_private" 
                    label="resources.conversation_notes.fields.is_private" 
                />
                <RadioButtonGroupInput 
                    source="status" 
                    choices={statuses} 
                    defaultValue={'none'} 
                    label="resources.conversation_notes.fields.status" 
                />
                <ReferenceInput source="assigned_to" reference="users">
                    {usersLoading ? (
                        <span>Loading users...</span>
                    ) : (
                        <AutocompleteInput 
                            source="assigned_to"
                            choices={users}
                            optionText={(choice) => `${choice.first_name} ${choice.last_name} (${choice.email})`} 
                            label="resources.conversation_notes.fields.assigned_to"
                            filterToQuery={(searchText) => ({
                                first_name: searchText ? `%${searchText}%` : undefined,
                                customer_id: assistant?.customer_id
                            })}
                            fullWidth
                        />
                    )}
                </ReferenceInput>
            </SimpleForm>
        </EditInDialogButton>
    );
};


export const Task = () => {
    const record = useRecordContext();
    if (!record) {
        return null; // Of een placeholder/loading state
    }
    return (
        <ReferenceManyField reference="conversation-notes" target="conversation_id" source="id">
            <AddNoteForm />
            <Datagrid bulkActionButtons={false} size="medium">
                <TextField source="content" label="resources.conversation_notes.fields.content" />
                <BooleanField source="is_private" label="resources.conversation_notes.fields.is_private" />
                <ReferenceField source="created_by" reference="users" link={false} label="resources.conversation_notes.fields.created_by" emptyText='resources.generic.unavailable'>
                    <TextField source="first_name" />
                </ReferenceField>
                <ReferenceField source="assigned_to" reference="users" link={false} label="resources.conversation_notes.fields.assigned_to" emptyText='resources.generic.unavailable'>
                    <TextField source="first_name" />
                </ReferenceField>
                <TextField source="status" label="resources.conversation_notes.fields.status" />
                <DateField source="created_at" label="resources.conversation_notes.fields.created_at" />
                <EditNoteButton/>
            </Datagrid>
        </ReferenceManyField>
    )
};


const TaskListActionValue = (id:any, resource:any, record:any) => {
    return `/conversations/${record.conversation_id}/show`;
};


const TaskListActions = ({ filters, filtersOpen, setFiltersOpen, setFilters }: {
    filters: any, 
    filtersOpen: boolean, 
    setFiltersOpen: (open: boolean) => void,
    setFilters: (filters: any) => void,
}) => (
    <TopToolbar
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
        }}
    >
        <FiltersDrawer
            open={filtersOpen}
            onOpen={() => setFiltersOpen(true)}
            onClose={() => setFiltersOpen(false)}
            filters={filters}
            onFilterChange={setFilters}
            onClearFilters={() => setFilters({})}
            filterComponents={TaskFilters({ filters, onFilterChange: setFilters })}
        />
    </TopToolbar>
);

export const TaskList = () => {
    const translate = useTranslate();
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filters, setFilters] = useState({});

    return (
        <Box p={2}>
            <ResourceTitle/>
            <List
                resource='conversation-notes'
                empty={false}
                sort={{ field: 'created_at', order: 'DESC' }}
                actions={
                    <TaskListActions 
                        filters={filters} 
                        filtersOpen={filtersOpen} 
                        setFiltersOpen={setFiltersOpen}
                        setFilters={setFilters}
                    />

                }
                pagination={<CustomPagination />}
                filter={filters}
            >
                <Datagrid 
                    bulkActionButtons={false} 
                    size="medium"
                    rowClick={TaskListActionValue}
                    sx={{
                        '& .column-conversation_id': {
                            maxWidth: '15em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                        '& .column-content': {
                            maxWidth: '15em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }
                    }}
                >
                    <DateField 
                        source="created_at" 
                        label="resources.conversation_notes.fields.created_at" 
                        options={{ year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }} /* @todo: fix this workaround and implement TZ correctly */ 
                        locale="nl-NL"
                        transform={(value: string) => new Date(value+'+00:00')}
                    />
                    <TextField source="content" label="resources.conversation_notes.fields.content" />
                    <ReferenceField 
                        source="conversation_id" 
                        reference="conversations" 
                        label={translate('resources.assistants.name', { smart_count: 1 })}
                        link={false}
                    >
                        <ReferenceField source="assistant_id" reference="assistants">
                            <TextField source="name" />
                        </ReferenceField>
                    </ReferenceField>
                    <BooleanField source="is_private" label="resources.conversation_notes.fields.is_private" />
                    <ReferenceField source="created_by" reference="users" link={false} label="resources.conversation_notes.fields.created_by">
                        <TextField source="first_name" />
                    </ReferenceField>
                    <ReferenceField source="assigned_to" reference="users" link={false} label="resources.conversation_notes.fields.assigned_to" emptyText='resources.generic.unavailable'>
                        <TextField source="first_name" />
                    </ReferenceField>
                    <TextField source="status" label="resources.conversation_notes.fields.status" />
                </Datagrid>
            </List>
        </Box>
    );
};

export default TaskList;
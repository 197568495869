import * as React from 'react';
import { Loading, useGetManyAggregate, useRecordContext, usePermissions, FunctionField, useTranslate, DateField, List, SimpleList, ReferenceField, TextField, Show, TopToolbar, useShowController, useGetOne, useGetList, useDataProvider, SearchInput, useResourceContext, Form, TextInput } from 'react-admin';
import { Box, Paper, Tabs, Tab } from '@mui/material';
import DurationField from './DurationField';
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate, Route, Routes, useParams } from 'react-router-dom';
import { IconX, IconClipboardList, IconMessageDots, IconNote, IconSettings } from '@tabler/icons-react';
import CustomPagination from './components/react-admin/CustomPagination';
import CustomChip from './components/react-admin/CustomChip';
import { useTheme } from '@mui/material/styles';
import { Drawer } from '@mui/material';
import { RatingBadge } from './components/RatingBadge';
import { allTagsWithEmoji } from './TagsManager';
import { Datagrid } from 'react-admin';
import { ConversationDetails } from './components/conversations/ConversationDetails';
import ConversationTranscript from './components/conversations/ConversationTranscript';
import AudioPlayer from './components/AudioPlayer';
import ConversationNotes from './components/conversations/ConversationNotes';
import { useState, useEffect } from 'react';
import { ConversationFilters } from './components/conversations/ConversationFilters';
import { ConversationAdmin } from './components/conversations/ConversationAdmin';
import { outcomeConfig, OutcomeType } from './config/outcomeConfig';
import { Tooltip } from '@mui/material';
import { ResourceTitle } from './layout/ResourceTitle';

const tags = Object.keys(allTagsWithEmoji).map(key => ({ id: key, name: key }));

export const TagsManagerWrapper = () => {
    const record = useRecordContext();
    if (!record?.tags) return null;
    if (typeof record.tags === 'string') {
        record.tags = [record.tags];
    }

    return (
        <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
            {record.tags.map((tag: string) => (
                <CustomChip
                    key={tag}
                    label={allTagsWithEmoji[tag] || tag}
                    variant="filled"
                    size="small"
                />
            ))}
        </div>
    );
};

export const LocalCreatedAt = (props: any) => {
    const record = useRecordContext();
    const { data: assistants, isLoading, error } = useGetManyAggregate('assistants', { ids: [record?.assistant_id] });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
            <DateField
            source={props.source}
            options={{ year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit', timeZone: assistants?.[0]?.timezone }}
            transform={(value: string) => new Date(value+'+00:00')}
            />
        )
};



const filterToQuery = (searchText: string) => ({
    _or: [
        { q: searchText },
        { topic: searchText },
        { summary: searchText },
        { from_id: searchText },
        { from_name: searchText }
    ]
});


const Assistant = () => {
    const record = useRecordContext();
    const { data: assistants, isLoading, error } = useGetManyAggregate('assistants', { ids: [record?.assistant_id] });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>{assistants?.[0]?.name}</div>;
};

const ListActions = ({ filters, filtersOpen, setFiltersOpen, setFilters, permissions, theme }: {
    filters: any, 
    filtersOpen: boolean, 
    setFiltersOpen: (open: boolean) => void,
    setFilters: (filters: any) => void,
    permissions: any,
    theme:  any
}) => (


    <Box
        sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        }}
    >
        <ConversationFilters
            open={filtersOpen}
            onOpen={() => setFiltersOpen(true)}
            onClose={() => setFiltersOpen(false)}
            filters={filters}
            onFilterChange={setFilters}
            permissions={permissions}
            onClearFilters={() => setFilters({})}
        />
    </Box>
);


interface Filters {
  date_from?: string;
  date_to?: string;
  assistant_id?: number[];
  'assistant.customer_id'?: number[];
  outcome?: string;
}

const postFilters = [
    <TextInput 
        source="from_id" 
        alwaysOn 
        label="Search"
        placeholder="Enter phone number..."
    />,
];

export const ConversationList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filters, setFilters] = useState<Filters>({});
    const theme = useTheme();

    return (
        <Box p={2}>
            <ResourceTitle />
            <List
                exporter={false} 
                sort={{ field: 'created_at', order: 'DESC' }}
                filter={filters}
                filters={postFilters}
                actions={
                    <ListActions 
                        filters={filters} 
                        filtersOpen={filtersOpen} 
                        setFiltersOpen={setFiltersOpen}
                        setFilters={setFilters}
                        permissions={permissions}
                        theme={theme}
                    />
                }
                pagination={<CustomPagination />}
                empty={false}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={<LocalCreatedAt source="created_at" label="resources.assistants.fields.created_at" />}
                        secondaryText={record => 
                            <>
                            <strong>{translate('resources.conversations.fields.outcome')}:</strong> {record.outcome ? translate('resources.conversations.outcomes.'+record.outcome) : <i>n/a</i>}
                            <br />
                            <strong>{translate('resources.conversations.fields.topic')}:</strong> {record.topic ? record.topic : <i>n/a</i>}
                            <br />
                            {['admin', 'partner'].includes(permissions) && <TagsManagerWrapper />}
                            <br />
                            <strong>{translate('resources.conversations.fields.summary')}:</strong> {record.summary ? record.summary : <i>n/a</i>}
                            </>
                        }
                        tertiaryText={record => 
                            <div style={{ textAlign: 'right', fontSize: '0.875rem' }}>
                            {record.from_id ? record.from_id : <i>n/a</i>}
                            {record.from_name ? ' - ' + record.from_name : ''}
                            <br />
                            <Assistant />
                            </div>
                        }
                        linkType={record => record.canEdit ? "edit" : "show"} 
                    />
                ) : (
                    <Datagrid rowClick="show" bulkActionButtons={false}
                    sx={{
                        '& .column-summary': {
                            '& > *': {
                                whiteSpace: 'normal', 
                                overflow: 'hidden', 
                                WebkitLineClamp: 2, 
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                lineHeight: '1.2em',
                                maxWidth: '15em',
                            }
                        },
                        '& .column-phone_number': {
                            display: 'table-cell',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '8em',
                        },
                        '& .column-outcome': {
                            display: 'table-cell',
                            maxWidth: '10em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                        '& .column-tags': {
                            display: 'table-cell',
                            maxWidth: '15em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                        '& .column-assistant_id': {
                            display: 'table-cell',
                            maxWidth: '12em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                    }}
                    >
                        <FunctionField 
                            source="created_at" 
                            label="Creation time"
                            render={(record) => (
                                <div>
                                    <LocalCreatedAt source="created_at" />
                                    <div style={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: theme.spacing(0.5) }}>
                                        <DurationField source="duration" />
                                    </div>
                                </div>
                            )}
                        />
                        <FunctionField
                            source="from_id"
                            label="Contact"
                            render={(record) => (
                                <div>
                                    {record.from_id}
                                    <div style={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: theme.spacing(0.5) }}>
                                        {record.from_name || 'Unknown'}
                                    </div>
                                </div>
                            )}
                        />
                        <TextField source="topic" label="Topic" />
                        <TextField source="summary" label="Summary" />
                        <FunctionField
                            source="outcome"
                            label="Outcome"
                            render={(record: Record<string, any>) => {
                                if (!record?.outcome) return null;
                                const config = outcomeConfig[record.outcome as OutcomeType] || outcomeConfig['no_action'];
                                const Icon = config.icon;

                                return (
                                    <Tooltip 
                                        title={translate(`resources.conversations.outcomes.${record.outcome}`)}
                                        placement="top"
                                        arrow
                                        PopperProps={{
                                            sx: {
                                                zIndex: 1300
                                            }
                                        }}
                                    >
                                        <span style={{ 
                                            display: 'inline-block',
                                            cursor: 'pointer'
                                        }}>
                                            <CustomChip 
                                                variant="outlined"
                                                color={'default'}
                                                icon={<Icon size={config.size} stroke={1.5} color={config.color}/>}
                                                label=""
                                                sx={{
                                                    '& .MuiChip-label': { display: 'none' },
                                                    height: 24,
                                                    minWidth: 24,
                                                    '& .MuiChip-icon': {
                                                        marginLeft: '0',
                                                        marginRight: '0',
                                                    },
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                    </Tooltip>
                                );
                            }}
                        />
                        <FunctionField source="tags" label="Tags" render={record => <TagsManagerWrapper />} />
                        <ReferenceField source="assistant_id" reference="assistants" label="Assistant">
                            <TextField source="name" />
                        </ReferenceField>
                        {permissions === 'admin' && 
                            <FunctionField 
                                label="Rating" 
                                render={(record: any) => {
                                    const rating = JSON.parse(record.meta).rating;
                                    return rating ? <RatingBadge value={rating} /> : null;
                                }} 
                            />
                        }
                    </Datagrid>
                )}
            </List>
            <Routes>
                <Route
                    path=":id"
                    element={<ConversationShow />}
                />
            </Routes>
        </Box>
    );
};

export const ConversationShow = () => {
    const { record } = useShowController();
    const { permissions } = usePermissions();
    const navigate = useNavigate();
    const { data: listData } = useGetList('conversations', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'created_at', order: 'DESC' }
    });
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [selectedTab, setSelectedTab] = React.useState('overview');
    const dataProvider = useDataProvider();

    const apiUrl = import.meta.env.VITE_API_URL;
    const auth = localStorage.getItem('auth');
    const parsedAuth = JSON.parse(auth || '{}');

    useEffect(() => {
        const handleKeyPress = async (event: KeyboardEvent) => {
            if (!record || !listData) return;
            
            const currentIndex = listData.findIndex((item: any) => item.id === record.id);

            if (event.key === 'ArrowRight' && currentIndex < listData.length - 1) {
                navigate(`/conversations/${listData[currentIndex + 1].id}/show`, {
                    replace: true,
                    state: { _scrollToTop: false }
                });
            } else if (event.key === 'ArrowLeft' && currentIndex > 0) {
                navigate(`/conversations/${listData[currentIndex - 1].id}/show`, {
                    replace: true,
                    state: { _scrollToTop: false }
                });
            }

            // Handle rating (only for admin in admin tab)
            if (permissions === 'admin' && selectedTab === 'admin') {
                const rating = parseInt(event.key);
                if (rating >= 1 && rating <= 5) {
                    try {
                        const meta = JSON.parse(record.meta || '{}');
                        meta.rating = rating;
                        const newMeta = JSON.stringify(meta);

                        await dataProvider.update('conversations', {
                            id: record.id,
                            data: { meta: newMeta },
                            previousData: record,
                        });

                        setOpen(false);
                        setTimeout(() => {
                            navigate('/conversations', {
                                replace: true,
                                state: { _scrollToTop: false }
                            });
                        }, 300);
                    } catch (error) {
                        console.error('Error updating rating:', error);
                    }
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [record, listData, navigate, permissions, selectedTab, dataProvider]);

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            navigate('/conversations', {
                replace: true,
                state: { _scrollToTop: false }
            });
        }, 300);
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'overview':
                return <ConversationDetails record={record}/>;
            case 'transcript':
                return <ConversationTranscript messages={record.messages} />;
            case 'notes':
                return <ConversationNotes record={record}/>;
            case 'admin':
                return record?.meta ? (
                    <ConversationAdmin meta={record.meta} />
                ) : null;
            default:
                return null;
        }
    };

    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

    return (
        <>
            <ConversationList />
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                SlideProps={{
                    appear: true,
                    direction: "left",
                    timeout: 300
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: {
                            xs: '100%',
                            sm: '100%',
                            md: '700px',
                        },
                        padding: {
                            xs: 2,
                            sm: 2,
                            md: 3,
                        },
                        backgroundColor: theme.palette.background.paper,
                    },
                }}
            >
                <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}>
                    <Box sx={{ mb: 3, position: 'relative' }}>
                        <IconX
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                            size={24}
                        />
                        <Typography variant="h6" fontWeight="bold" fontSize="1.25rem">
                            Conversation Details
                        </Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <AudioPlayer 
                            src={`${apiUrl}/conversations/${record?.id}/recording.mp3?access_token=${parsedAuth.access_token}`}
                        />
                    </Box>

                    <Paper sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        mb: 2,
                    }}>
                        <Tabs
                            value={selectedTab}
                            onChange={(_, newValue) => setSelectedTab(newValue)}
                            variant={isSmall ? "scrollable" : "fullWidth"}
                            scrollButtons={isSmall ? "auto" : false}
                            sx={{
                                '& .MuiTab-root': {
                                    minWidth: { xs: 'auto', sm: 0 },
                                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                    px: { xs: 1, sm: 2 },
                                }
                            }}
                        >
                            <Tab
                                icon={<IconClipboardList size="1rem" />}
                                label="Overview"
                                value="overview"
                                iconPosition="start"
                            />
                            <Tab
                                icon={<IconMessageDots size="1rem" />}
                                label="Transcript"
                                value="transcript"
                                iconPosition="start"
                            />
                            <Tab
                                icon={<IconNote size="1rem" />}
                                label="Notes"
                                value="notes"
                                iconPosition="start"
                            />
                            {record?.meta && permissions === 'admin' && (
                                <Tab
                                    icon={<IconSettings size="1rem" />}
                                    label="Admin"
                                    value="admin"
                                    iconPosition="start"
                                />
                            )}
                        </Tabs>
                    </Paper>
                    <Box sx={{ 
                        overflow: 'auto',
                        flexGrow: 1,
                    }}>
                        {selectedTab === 'overview' && <ConversationDetails record={record} />}
                        {selectedTab === 'transcript' && <ConversationTranscript messages={record?.messages} />}
                        {selectedTab === 'notes' && <ConversationNotes record={record} />}
                        {selectedTab === 'admin' && record?.meta && <ConversationAdmin meta={record.meta} />}
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export const TwilioRedirect = () => {
    const { twilio_id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, error } = useGetOne(
        'conversations',
        { id: twilio_id },
        {
            onSuccess: (data) => {
                if (data?.id) {
                    navigate(`/conversations/${data.id}/show`, { replace: true });
                }
            },
            onError: () => {
                navigate('/conversations', { replace: true });
            }
        }
    );

    if (isLoading) return null;
};
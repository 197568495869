import { Box, Paper, Typography, Grid } from "@mui/material";
import { useTranslate } from "react-admin";
import { RatingBadge } from "../RatingBadge";
import { useTheme } from '@mui/material/styles';
import { IconStarFilled } from '@tabler/icons-react';

interface ConversationAdminProps {
    meta: string;
}

export const ConversationAdmin = ({ meta }: ConversationAdminProps) => {
    const translate = useTranslate();
    const theme = useTheme();
    const parsedMeta = JSON.parse(meta);

    return (
        <Paper sx={{
            p: theme.spacing(2),
            boxShadow: theme.shadows[0],
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box height="100%">
                <Typography variant="h5" gutterBottom fontWeight="bold" mb={theme.spacing(2)}>
                    {translate('resources.conversations.fields.admin')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(2) }}>
                                    <IconStarFilled 
                                        size="1rem" 
                                        style={{ marginRight: 8 }}
                                    />
                                    <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                        {translate('resources.conversations.fields.rating')}:
                                    </Typography>
                                </Box>
                                <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                    {parsedMeta.rating ? <RatingBadge value={parsedMeta.rating} /> : 'Unrated'}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
    );
};
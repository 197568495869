import React, { useEffect, useState } from 'react';
import { 
    Toolbar, 
    SaveButton, 
    DeleteButton, 
    useDataProvider, 
    SelectInput, 
    Create, 
    Edit, 
    SimpleForm, 
    Datagrid, 
    List, 
    TextField, 
    EmailField, 
    TextInput, 
    minLength, 
    required, 
    email, 
    useGetIdentity,
    useTranslate,
    BooleanInput,
    TopToolbar,
    CreateButton,
    PasswordInput,
    regex
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { getReadableLocaleName } from './i18nProvider';
import EmptyUser from './user/EmptyUser';
import { Box, Typography } from '@mui/material';
import PhoneNumberInput from './PhoneNumberInput';
import CustomPagination from './components/react-admin/CustomPagination';

export const useUserContext = () => {
    const { customerId, partnerId } = useParams();
    const { identity } = useGetIdentity();

    const defaultValues = {
        customer_id: customerId || (!partnerId && identity && identity.customer_id) || null,
        partner_id: partnerId || (!customerId && identity && identity.partner_id) || null,
    };

    const getFilter = () => {
        if (customerId) {
            return { customer_id: parseInt(customerId) };
        } else if (partnerId) {
            return { partner_id: parseInt(partnerId) };
        } else if (identity?.customer_id) {
            return { customer_id: identity.customer_id };
        } else if (identity?.partner_id) {
            return { partner_id: identity.partner_id };
        }
        return {};
    };

    const getCreatePath = () => {
        if (customerId) {
            return `/customers/${customerId}/users/create`;
        } else if (partnerId) {
            return `/partners/${partnerId}/users/create`;
        }
        return '/users/create';
    };

    const getEditPath = (record) => {
        if (customerId) {
            return `/customers/${customerId}/users/${record.id}`;
        } else if (partnerId) {
            return `/partners/${partnerId}/users/${record.id}`;
        }
        return `/users/${record.id}`;
    };

    const redirect = () => {
        if (customerId) {
            return `customers/${customerId}/users`;
        } else if (partnerId) {
            return `partners/${partnerId}/users`;
        }
        return 'users';
    };

    return {
        customerId,
        partnerId,
        defaultValues,
        getFilter,
        getCreatePath,
        getEditPath,
        redirect,
    };
};

const CustomToolbar = () => {
  const { redirect } = useUserContext();

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SaveButton />
        <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
);
}

const LocaleInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLocales = async () => {
            try {
                setLoading(true);
                const { data } = await dataProvider.custom('users/locales', { method: 'GET' });
                const formattedChoices = data.map((locale: string) => ({
                    id: locale,
                    name: getReadableLocaleName(locale)
                }));
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching locales:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLocales();
    }, [dataProvider]);

    if (loading) {
        return null; // Of een loading indicator
    }

    return (
        <SelectInput
            source="locale"
            choices={choices}
            label="resources.generic.locale"
            isRequired
            defaultValue={choices[0]?.id}
        />
    );
};



export const UserList = () => {
    const { getFilter, getCreatePath, getEditPath } = useUserContext();
    const translate = useTranslate();
    const ListActions = () => (
        <TopToolbar>
            <CreateButton 
                label="Create User"
                to={getCreatePath()}
            />
        </TopToolbar>
    );
    return (
        <Box p={2}>
        <Typography
            variant="h3"
            fontWeight="bold"
        >
            {translate('resources.generic.users')}
        </Typography>
        <List
            exporter={false} 
            resource='users' 
            empty={<EmptyUser translate={translate}/>}
            filter={getFilter()}
            actions={<ListActions />}
            pagination={<CustomPagination />}
        >
            <Datagrid rowClick={(id, resource, record) => getEditPath(record)} bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <EmailField source="email" />
            </Datagrid>
        </List>
        </Box>
    );
};

export const UserEdit = () => {
    const { redirect } = useUserContext();
    const translate = useTranslate();

    return (
        <Edit title="resources.users.edit" resource='users'>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <TextInput source="first_name" validate={required()} />
                <TextInput source="last_name" validate={required()} />
                <TextInput source="email" type="email" validate={[required(), email()]} />
                <PhoneNumberInput source="phone_number" isRequired={false} />
                <BooleanInput
                    source="two_factor_enabled"
                    label="resources.users.fields.requires_2fa"
                    validate={(value, allValues) => {
                        if (value && (!allValues.phone_number || allValues.phone_number.trim() === '')) {
                            return 'app.validation.phone_number_required_for_2fa';
                        }
                        return undefined;
                    }}
                />
                <PasswordInput source="new_password"
                validate={[
                    minLength(8, translate('app.validation.minLength')),
                    regex(/[A-Z]/, translate('app.validation.password.uppercase')),
                    regex(/[!@#$%^&*]/, translate('app.validation.password.special'))
                ]}
                 autoComplete='new-password' />
                <LocaleInput />
            </SimpleForm>
        </Edit>
    );
};

const equalToPassword = (value: any, allValues: any) => {
    if (value !== allValues.password) {
        return 'The two passwords must match';
    }
};

export const UserCreate = () => {
    const { defaultValues, redirect } = useUserContext();
    const translate = useTranslate();

    return (
        <Create resource='users' redirect={redirect}>
            <SimpleForm defaultValues={{...defaultValues}}>
                <TextInput source="email" type="email" validate={[required(), email()]} />
                <TextInput source="first_name" validate={required()} />
                <TextInput source="last_name" validate={required()} />
                <PhoneNumberInput source="phone_number" isRequired={false} />
                <BooleanInput
                    source="two_factor_enabled" 
                    label="resources.users.fields.requires_2fa"
                    validate={(value, allValues) => {
                        if (value && (!allValues.phone_number || allValues.phone_number.trim() === '')) {
                            return 'app.validation.phone_number_required_for_2fa';
                        }
                        return undefined;
                    }}
                />

                {defaultValues.customer_id && (
                    <TextInput source="customer_id" sx={{display: 'none'}} />
                )}

                {defaultValues.partner_id && (
                    <TextInput source="partner_id" sx={{display: 'none'}} />
                )}

                <LocaleInput />
                <PasswordInput 
                    source="password" 
                    validate={[
                        required(translate('app.validation.required')),
                        minLength(8, translate('app.validation.minLength')),
                        regex(/[A-Z]/, translate('app.validation.password.uppercase')),
                        regex(/[!@#$%^&*]/, translate('app.validation.password.special'))
                    ]} 
                    autoComplete='new-password'
                />
                <PasswordInput
                    source="confirm_password" 
                    validate={[equalToPassword, required()]} 
                    autoComplete='new-password'
                />
            </SimpleForm>
        </Create>
    );
};
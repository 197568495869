import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    Box,
    Button,
    Typography,
    InputLabel,
} from '@mui/material';
import { useNotify, useTranslate, Form, TextInput, email } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from './layout/Logo';
import { useDataProvider } from 'react-admin';


// Custom validator that doesn't show asterisk
const requiredValidate = (value: any) => 
    value ? undefined : 'Required';

export const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const handleSubmit = async (values: any) => {
        setLoading(true);

        try {
            await dataProvider.custom('forgot-password', {
                method: 'POST',
                postdata: { email: values.email }
            });
            setSuccess(true);
            notify('app.auth.password_reset_instructions_sent', { type: 'success' });
        } catch (error: any) {
            if (error.code) {
                notify(`app.auth.errors.${error.code}`, { type: 'error' });
            } else {
                notify('app.auth.generic_error', { type: 'error' });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form
            onSubmit={handleSubmit}
            noValidate
            defaultValues={{ email: '' }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F7F7F2'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: 'translateY(-15%)',
                    }}
                >
                    <Box
                        sx={{
                            marginBottom: '2em',
                            transform: 'scale(0.75)',
                        }}
                    >
                        <Logo />
                    </Box>
                    <Card sx={{ minWidth: 400, padding: '3em' }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    marginBottom: '2em',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    color: '#172121',
                                    fontSize: '1.1rem', 
                                    maxWidth: '400',
                                }}
                            >
                                {translate('app.auth.forgot_password')}
                            </Typography>
                            {!success ? (
                                <>
                                <Box sx={{ marginBottom: '2em' }}>
                                    <InputLabel
                                        htmlFor="email"
                                        sx={{
                                            marginBottom: '0.5em',
                                            color: '#172121',
                                            fontWeight: 500,
                                            fontSize: '0.9rem',
                                            textAlign: 'left',
                                            display: 'block',
                                            width: '100%'
                                        }}
                                    >
                                        {translate('app.auth.email')}
                                    </InputLabel>
                                    <TextInput
                                        autoFocus
                                        source="email"
                                        autoComplete="email"
                                        disabled={loading}
                                        validate={[requiredValidate, email()]}
                                        fullWidth
                                        helperText={false}
                                        label=""
                                    />
                                </Box>
                                <Box sx={{ marginBottom: 'em' }}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={loading}
                                            fullWidth
                                            sx={{ mt: 2, mb: 2 }}
                                        >
                                        {loading ? (
                                            <CircularProgress size={24} color="inherit" />
                                        ) : (
                                            translate('app.auth.send_reset_instructions')
                                        )}
                                        </Button>
                                    <Link
                                        to="/login"
                                        style={{
                                            color: '#44937D',
                                            textDecoration: 'none',
                                            display: 'block',
                                            textAlign: 'left',
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        {translate('app.auth.back_to_login')}
                                    </Link>
                                </Box>
                                </>
                            ) : (
                                <>
                                    <Typography 
                                        sx={{
                                            mb: 3, 
                                            mt: 2,
                                            textAlign: 'center',
                                            margin: '0 auto',
                                            maxWidth: '300px'
                                        }} 
                                    >
                                        {translate('app.auth.if_account_exists')}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        component={Link}
                                        to="/login"
                                        color="primary"
                                        fullWidth
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        {translate('app.auth.back_to_login')}
                                    </Button>
                                </>
                            )}
                    </Card>
                </Box>
            </Box>
        </Form>
    );
};
import { ReferenceArrayInput, AutocompleteArrayInput, SelectInput, useGetList, useTranslate } from "react-admin";
import { DateRangeFilter } from '../dashboard/DateRangeFilter';
import { FiltersDrawer } from "../FiltersDrawer";
import { Box, Typography } from '@mui/material';
import { IconCalendar, IconUser, IconBuilding, IconFlag } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';

interface ConversationFiltersProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  filters: any;
  onFilterChange: (filters: any) => void;
  permissions: string;
  onClearFilters: () => void;
}

export const ConversationFilters = ({ 
  open, 
  onClose, 
  onOpen, 
  filters, 
  onFilterChange, 
  permissions,
  onClearFilters
}: ConversationFiltersProps) => {
  const translate = useTranslate();
  const theme = useTheme();
  
  const { data: assistants } = useGetList('assistants', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'id', order: 'ASC' }
  });

  const filterComponents = [
    <Box >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
        <IconCalendar size="1rem" style={{ marginRight: 8 }} />
        <Typography variant="body2" color="textSecondary">
          {translate('resources.filters.date_range')}:
        </Typography>
      </Box>
      <Box sx={{mb: theme.spacing(4) }}>
        <DateRangeFilter 
          onChange={(dates) => {
            onFilterChange({ 
              ...filters,
              date_from: dates.start ? dates.start.split('T')[0] : undefined,
              date_to: dates.end ? dates.end.split('T')[0] : undefined
            });
          }} 
          value={filters.date_from ? [
            new Date(filters.date_from),
            new Date(filters.date_to || filters.date_from)
          ] : undefined}
        />
      </Box>
    </Box>,

    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
        <IconUser size="1rem" style={{ marginRight: 8 }} />
        <Typography variant="body2" color="textSecondary">
          {translate('resources.filters.assistant')}:
        </Typography>
      </Box>
      <Box sx={{mb: theme.spacing(4) }}>
        <ReferenceArrayInput source="assistant_id" reference="assistants">
          <AutocompleteArrayInput 
            fullWidth
            helperText={false}
            filterToQuery={searchText => ({ name: searchText })}
            onChange={(data) => onFilterChange({ 
              ...filters,
              assistant_id: data 
            })} 
            defaultValue={filters.assistant_id}
          />
        </ReferenceArrayInput>
      </Box>
    </Box>,

    ['admin', 'partner'].includes(permissions) && (
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
          <IconBuilding size="1rem" style={{ marginRight: 8 }} />
          <Typography variant="body2" color="textSecondary">
            {translate('resources.filters.customer')}:
          </Typography>
        </Box>
        <Box sx={{mb: theme.spacing(4) }}>
          <ReferenceArrayInput source="assistant.customer_id" reference="customers">
            <AutocompleteArrayInput 
              optionText="name"
              fullWidth
              helperText={false}
              filterToQuery={searchText => ({ name: searchText })}
              onChange={(data) => onFilterChange({ 
                ...filters,
                'assistant_id': data ? data.map((customerId: number) => 
                  assistants?.filter(a => a.customer_id === customerId).map(a => a.id)
                ).flat() : undefined
              })} 
              defaultValue={filters['assistant_id']}
            />
          </ReferenceArrayInput>
        </Box>
      </Box>
    ),

    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
        <IconFlag size="1rem" style={{ marginRight: 8 }} />
        <Typography variant="body2" color="textSecondary">
          {translate('resources.filters.outcome')}:
        </Typography>
      </Box>
      <Box sx={{mb: theme.spacing(4) }}>
        <SelectInput
          source="outcome"
          emptyText="resources.conversations.outcomes.all"
          fullWidth
          choices={[        
            { id: 'completed', name: 'resources.conversations.outcomes.completed' },
            { id: 'caller_follow_up', name: 'resources.conversations.outcomes.caller_follow_up' },
            { id: 'business_follow_up', name: 'resources.conversations.outcomes.business_follow_up' },
            { id: 'transferred', name: 'resources.conversations.outcomes.transferred' },
            { id: 'information_provided', name: 'resources.conversations.outcomes.information_provided' },
            { id: 'no_action', name: 'resources.conversations.outcomes.no_action' },
          ]}
          onChange={(event) => {
            const newFilters = { ...filters };
            const value = event?.target?.value;
            if (value) {
              newFilters.outcome = value;
            } else {
              delete newFilters.outcome;
            }
            onFilterChange(newFilters);
          }}
          defaultValue={filters.outcome}
        />
      </Box>
    </Box>
  ];

  return (
    <FiltersDrawer
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      filters={filters}
      onFilterChange={onFilterChange}
      onClearFilters={onClearFilters}
      filterComponents={filterComponents}
    />
  );
};
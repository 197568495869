import React, { useRef, useState, useEffect } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, TextField, Button, Paper, List, ListItem, ListItemText, CircularProgress, IconButton } from '@mui/material';
import { Edit as EditIcon, Cancel as CancelIcon } from '@mui/icons-material';
import { useAuthenticated } from 'react-admin';
import { useTheme } from '@mui/material/styles';

const ChatComponent = () => {
  useAuthenticated(); 
  const [loading, setLoading] = useState(false);
  const { id, phoneNumber } = useParams();
  const dataProvider = useDataProvider();
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const messagesEndRef = useRef(null);

  const translate = useTranslate();
  const theme = useTheme();

  const getRoleBackgroundColor = (role) => {
    return theme.customComponents?.StructuredJsonField?.roles[role]?.bgColor || 
           theme.customComponents?.StructuredJsonField?.roles.default.bgColor;
  };

  useEffect(() => {
    startConversation();
  }, []);
  
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };  

  const startConversation = async () => {
    try {
      const { data } = await dataProvider.custom('assistants/try', {
        method: 'post',
        postdata: { 
          assistant_id: parseInt(id),
          messages: [],
          caller_number: phoneNumber,
        },        
      });
      const { test_conversation_id, messages: newMessages } = data;

      setConversationId(test_conversation_id);
      setMessages(newMessages);
    } catch (error) {
      console.error('Error starting the conversation:', error);
    }
  };

  const sendMessage = async (userMessage, messageIndex = -1) => {
    setLoading(true);
    if (userMessage.trim() === '') return;
  
    let updatedMessages;
    if (messageIndex === -1) {
      // New message
      updatedMessages = [...messages, { role: 'user', content: userMessage }];
    } else {
      // Editing existing message
      updatedMessages = messages.slice(0, messageIndex + 1);
      updatedMessages[messageIndex] = { ...updatedMessages[messageIndex], content: userMessage };
    }

    // Ensure tool messages have the correct tool_call_id
    updatedMessages = updatedMessages.map((msg, index) => {
      if (msg.role === 'tool' && !msg.tool_call_id) {
        // Find the corresponding assistant message with tool_calls
        for (let i = index - 1; i >= 0; i--) {
          if (updatedMessages[i].role === 'assistant' && updatedMessages[i].tool_calls) {
            return { ...msg, tool_call_id: updatedMessages[i].tool_calls[0].id };
          }
        }
      }
      return msg;
    });

    setMessages(updatedMessages);
    setInput('');
    setEditingMessageId(null);
  
    try {
      const { data } = await dataProvider.custom('assistants/try', {
        method: 'post',
        postdata: {
          assistant_id: parseInt(id),
          test_conversation_id: conversationId,
          caller_number: phoneNumber,
          messages: updatedMessages.map(msg => ({
            role: msg.role,
            content: msg.content,
            ...(msg.function_call && { function_call: msg.function_call }),
            ...(msg.tool_calls && { tool_calls: msg.tool_calls }),
            ...(msg.tool_call_id && { tool_call_id: msg.tool_call_id }),
            ...(msg.refusal && { refusal: msg.refusal })
          })),
        },
      });
      const { messages: newMessages } = data;

      // Combine existing messages with new messages, ensuring tool_call_id is preserved
      setMessages(prevMessages => {
        const combinedMessages = [...prevMessages, ...newMessages];
        return combinedMessages.map((msg, index) => {
          if (msg.role === 'tool' && !msg.tool_call_id) {
            // Find the corresponding assistant message with tool_calls
            for (let i = index - 1; i >= 0; i--) {
              if (combinedMessages[i].role === 'assistant' && combinedMessages[i].tool_calls) {
                return { ...msg, tool_call_id: combinedMessages[i].tool_calls[0].id };
              }
            }
          }
          return msg;
        });
      });
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (index) => {
    setEditingMessageId(index);
    setInput(messages[index].content);
  };

  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setInput('');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Paper elevation={3} sx={{ overflow: 'auto', flexGrow: 1, display: 'flex', flexDirection: 'column-reverse' }}>
        <List>
          {messages.map((msg, index) => (
            <ListItem 
              key={index} 
              sx={{ bgcolor: getRoleBackgroundColor(msg.role) }}
            >
              <ListItemText
                primary={msg.role}
                secondary={
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {msg.tool_calls ? msg.tool_calls[0].function.name+' => '+msg.tool_calls[0].function.arguments : 
                     msg.content}
                  </span>
                }
              />
              {msg.role === 'user' && editingMessageId !== index && (
                <IconButton onClick={() => handleEdit(index)}>
                  <EditIcon />
                </IconButton>
              )}
              {msg.role === 'user' && editingMessageId === index && (
                <IconButton onClick={handleCancelEdit}>
                  <CancelIcon />
                </IconButton>
              )}
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </Paper>
      <Box sx={{ position: 'sticky', bottom: 0, width: '100%', display: 'flex', alignItems: 'center', gap: 1, padding: 1 }}>
        <TextField
          label={editingMessageId !== null ? translate('resources.assistants.try.edit_message') : translate('resources.assistants.try.type_your_message')}
          variant="outlined"
          fullWidth
          autoComplete='off'
          value={input}
          onChange={e => setInput(e.target.value)}
          onKeyPress={e => e.key === 'Enter' && sendMessage(input, editingMessageId !== null ? editingMessageId : -1)}
        />
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => sendMessage(input, editingMessageId !== null ? editingMessageId : -1)} 
          disabled={!input.trim()}
        >
          {editingMessageId !== null ? translate('resources.assistants.try.update') : translate('resources.assistants.try.send')}
        </Button>
        {loading && <CircularProgress size={24} />}
      </Box>
    </Box>
  );
};

export default ChatComponent;
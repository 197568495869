import { ReferenceArrayInput, AutocompleteArrayInput, SelectInput, ReferenceInput, AutocompleteInput, BooleanInput, useTranslate } from "react-admin";
import { Box, Typography } from '@mui/material';
import { IconUser, IconBuilding, IconFlag, IconLock } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';

const statuses = [
    {id:'none', name:'resources.conversation_notes.statuses.none'},
    {id:'open', name:'resources.conversation_notes.statuses.open'},
    {id:'closed', name:'resources.conversation_notes.statuses.closed'}
];

interface TaskFiltersProps {
    filters: any;
    onFilterChange: (filters: any) => void;
}

export const TaskFilters = ({ filters, onFilterChange }: TaskFiltersProps) => {
    const theme = useTheme();
    const translate = useTranslate();

    return [
        <Box key="assistant" sx={{ mb: theme.spacing(4) }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                <IconBuilding size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.assistants.name', { smart_count: 2 })}:
                </Typography>
            </Box>
            <ReferenceArrayInput source="conversation.assistant_id" reference="assistants">
                <AutocompleteArrayInput
                    optionText="name"
                    fullWidth
                    helperText={false}
                    filterToQuery={searchText => ({ name: searchText })}
                    onChange={(value) => onFilterChange({ 
                        ...filters, 
                        conversation: {
                            ...filters.conversation,
                            assistant_id: value 
                        }
                    })}
                    defaultValue={filters.conversation?.assistant_id}
                />
            </ReferenceArrayInput>
        </Box>,

        <Box key="status" sx={{ mb: theme.spacing(4) }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                <IconFlag size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.conversation_notes.fields.status')}:
                </Typography>
            </Box>
            <SelectInput
                source="status"
                choices={statuses}
                fullWidth
                onChange={(event) => {
                    const value = event?.target?.value;
                    onFilterChange({ 
                        ...filters, 
                        status: value || undefined 
                    });
                }}
                defaultValue={filters.status}
            />
        </Box>,

        <Box key="assigned" sx={{ mb: theme.spacing(4) }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                <IconUser size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.conversation_notes.fields.assigned_to')}:
                </Typography>
            </Box>
            <ReferenceInput source="assigned_to" reference="users">
                <AutocompleteInput
                    optionText={(choice) => `${choice.first_name} ${choice.last_name}`}
                    fullWidth
                    helperText={false}
                    filterToQuery={(searchText) => ({
                        first_name: searchText,
                        last_name: searchText
                    })}
                    onChange={(value) => onFilterChange({ 
                        ...filters, 
                        assigned_to: value 
                    })}
                    defaultValue={filters.assigned_to}
                />
            </ReferenceInput>
        </Box>,

        <Box key="private" sx={{ mb: theme.spacing(4) }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(1) }}>
                <IconLock size="1rem" style={{ marginRight: 8 }} />
                <Typography variant="body2" color="textSecondary">
                    {translate('resources.conversation_notes.fields.is_private')}:
                </Typography>
            </Box>
            <BooleanInput 
                source="is_private"
                fullWidth
                onChange={(event) => {
                    const value = event?.target?.checked;
                    onFilterChange({ 
                        ...filters, 
                        is_private: value === undefined ? undefined : value
                    });
                }}
                defaultValue={filters.is_private}
            />
        </Box>
    ];
}; 